.detail-card-info {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.detail-card-info img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* max-height: 450px; */
}

.main-title {
  font-size: 36px;
  font-family: "Gilda Display", serif;
  font-weight: 400;
  position: relative;
  margin-bottom: 3%;
  line-height: 1.25em;
  text-align: left;
}

.table-floor ul {
  list-style: none;
}

.box-info ul {
  /* list-style: none; */
  padding: 0;
}

.box-info ul li i {
  font-size: 11px;
  color: #b79231;
  font-weight: 600;
}

.box-info ul li {
  font-size: 16px;
  margin-bottom: 5px;
}

.box-info {
  padding: 30px 40px;
  background-color: white;
  height: 100%;
}

.box-info h2 {
  font-size: 36px;
  font-family: "Gilda Display", serif;
  font-weight: 400;
  position: relative;
  margin-bottom: 3%;
  line-height: 1.25em;
  text-align: left;
}

.gallery {
  min-height: 300px;
}

.cta-section {
  background-attachment: fixed;
  background-position: top;
}

.cta-section h2 {
  font-size: 42px;
  font-family: "Gilda Display", serif;
  font-weight: 400;
  position: relative;
  margin-bottom: 3%;
  line-height: 1.25em;
  color: white;
  font-weight: 600;
  letter-spacing: 3px;
}

.cta-section p {
  color: white;
  font-size: 18px;
  margin: auto;
}

.bg-gold {
  background-color: #aa8453;
  color: white;
}

.contact-list i,
.contact-list p {
  font-size: 20px;
}

.contact-list {
  padding: 15px;
}

.map {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.blog-date {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: #aa8453;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  padding: 5px 10px;
  text-align: center;
  font-size: 11px;
}

.blog-date span {
  font-size: 25px;
}

.blog-img {
  height: 400px;
  overflow: hidden;
}

.blog-img img {
  transition: all 0.5s;
}

.blog-img:hover img {
  transform: scale(1.1);
}

.blog_search,
.widget_recent_posts {
  width: 100%;
  padding: 20px;
  background-color: white;
}

.icon-serach {
  position: absolute;
  top: 16px;
  right: 17px;
}

.widget-title {
  padding-bottom: 5px;
  border-bottom: 1px solid #eee3d0;
  font-size: 24px;
  line-height: 1.75em;
  margin-bottom: 15px;
  color: #222;
  font-family: "Gilda Display", serif;
}

.widget_recent_posts h5 {
  font-size: 17px;
  color: #aa8453;
  margin-bottom: 5px;
}

.widget_recent_posts p {
  font-size: 14px;
}

.comments img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.comment-text span {
  font-size: 13px;
}

.comment-text p {
  font-size: 14px;
  opacity: 0.8;
  margin-top: 5px;
}

.fixed-button {
  position: fixed;
  top: 45%;
  left: -58px;
  z-index: 999;
  transform: rotate(90deg);
}

.fixed-button button {
  position: relative;
  overflow: hidden;
}

.fixed-button button:hover {
  background-color: #f8f5f0;
  color: #aa8453;
}

/* .fixed-button button:hover::before {
  left: 120%;
  opacity: 0.65;
} */
.fixed-button button::before {
  background-color: #aa8453;
  content: "";
  height: 120px;
  left: -75px;
  opacity: 0;
  position: absolute;
  top: -35px;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  /* transition: all 1.6s stripes 4s linear infinite;; */
  animation: stripes 2s linear infinite;
  width: 30px;
}

/* project enquiry */
.project_qr{
  width: 200px;
  margin: 20px;
}

@keyframes stripes {
  0% {
    left: -2 0%;
    opacity: 0.65;
  }

  100% {
    left: 120%;
    opacity: 0.65;
  }
}

.succes-message {
  font-size: 16px;
  color: #aa8453;
  font-family: "Gilda Display", serif;
}

.ant-notification-notice-description {
  font-size: 17.5px !important;
  color: #aa8453 !important;
}

.ant-notification-notice-wrapper {
  border-radius: 3px !important;
}

/* blog fixed_search */
.fixed_search {
  position: fixed;
  width: 28%;
  top: 10%;
  right: 8%;
}

.highlight-banner{
 background-color: #a7804d;;
}

.blink-price{
  animation: blink 1s step-start infinite;
  font-size: 32px;
  font-weight:900;
  text-decoration: underline;
  color: #d79743;
}

.Pricing-Details{
  background-color: #eee3d0;
}

.Calculator-Table{
  border-collapse: initial !important;
  border-spacing: 0px 9px !important;
}

.Calculator-Table tr:not(:last-child) td{
  border-bottom: 0.5px solid #ead5b1;
}

.Terms-Table{
  border-collapse: initial !important;
  border-spacing: 5px 9px;
  background-color: #ead5b1;
  padding: 0px 10px 10px 10px;
  border-radius: 10px;
}

.Terms-Table tr td{
  border: 1px solid #a7804d;
  padding: 10px;
  color: black;
  border-radius: 5px;
}

.Price-table{
  background-color: transparent !important;
  width: 100%;
  border-collapse: initial;
  border-spacing: 0 9px;
}

.Price-table-scroll{
  background-color: transparent !important;
  /* min-width: 100%; */
  width: 1600px;
  overflow-x: auto;
  scrollbar-width:thin;
  border-collapse: initial;
  border-spacing: 0 9px;
}

.Price-table thead,
.Price-table-scroll thead{
  background-color: #AA8453;
  color: white;
}

.Price-table thead tr th,
.Price-table-scroll thead tr th{
  padding: 10px 15px;
}

.Price-table tbody tr:not(:last-child),
.Price-table-scroll tbody tr:not(:last-child){
  border-bottom: 1px solid #222;
  border-radius: 5px;
}

.Price-table tbody tr,
.Price-table-scroll tbody tr{
  background-color: #ead5b1;
}

/* For even-numbered tr elements in Price-table */
.Price-table tbody tr:nth-child(even),
.Price-table-scroll tbody tr:nth-child(even) {
  background-color: #F8F5F0;
}

.Price-table tbody tr{
  margin-top: 5px;
}

.Price-table tbody tr td,
.Price-table-scroll tbody tr td{
  padding: 15px 10px;
}



.Price-table tbody tr td,
.Price-table-scroll tbody tr td{
  margin: 10px 0px;
}

.Price-table-scroll th:last-child,
.Price-table-scroll th:nth-last-child(2){
  background-color: #AA8453;
}

/* Match sticky column background with other rows */
.Price-table-scroll tbody tr td:last-child,
.Price-table-scroll tbody tr td:nth-last-child(2) {
  background-color: inherit; /* Inherit row color */
}

/* Ensure alternate row colors apply to sticky columns */
.Price-table-scroll tbody tr:nth-child(even) td:last-child,
.Price-table-scroll tbody tr:nth-child(even) td:nth-last-child(2) {
  background-color: #F8F5F0; /* Light color for even rows */
}

.Price-table-scroll tbody tr:nth-child(odd) td:last-child,
.Price-table-scroll tbody tr:nth-child(odd) td:nth-last-child(2) {
  background-color: #ead5b1; /* Darker color for odd rows */
}

.Price-table-scroll th:last-child,
.Price-table-scroll td:last-child{
  width: 5%;
  position: sticky;
  right: 0;
  z-index: 10;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.Quntity-Button{
  background-color: #AA8453;
  border-radius: 10px;
}

.Cart-Button{
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
}

.Line{
  margin: 10px 0px 10px 0px;
  border: 1px solid #AA8453;
}

.Calculator-div{
  max-height: 450px;
  overflow-y: scroll;
  scrollbar-width: thin;

}

@media (max-width: 786px) {
  .project-info h6 {
    font-size: 16px;
  }

  .project-info .box {
    margin-top: 5px;
  }

  .contact-list {
    flex-wrap: wrap;
  }

  /* blog fixed_search */
  .fixed_search {
    position: static;
    width: auto;
  }
}

.Offer-Modal{
  padding: 0px !important;
  overflow: hidden;
  background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url("../Images/Popup_bg.avif");
  background-size: cover;
  position: relative;
}

.bg-green{
  background-color: #a7804d;
  color: white;
  padding: 15px;
  border-radius: 5px;
  width: fit-content;
  justify-content: center;
}

.Discount-banner{
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width : 768px) {
  .bg-gradientt .text-div{
      clip-path: none;
      background-color: rgb(18, 17, 19 , 0.80);
  }
}

.unsubscribe-form {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adds space between form elements */
  padding: 20px;
}

/* Style for the radio button container */
.radio-group {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds space between radio button and text */
}

/* Custom radio button design */
.custom-radio input {
  appearance: none;
  border-radius: 50%;
  border: 2px solid #a7804d;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-radio input:checked {
  background-color: #a7804d;
}

.custom-radio input:checked::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
}

/* Style for the radio button text */
.radio-text {
  font-size: 18px;
  color: #333;
  cursor: pointer;
}