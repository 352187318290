.banner-header {
    height: 55vh;
    position: relative;
    background-attachment: fixed;
    background-size: contain;
    background-position: center;
  }
  .project-banner{
    height: 75vh;
  }
  .bd-light-gold{
    background-color: #f8f5f0 !important;
  }
  .banner-top{
    margin-top: 90px;
    position: relative;
  z-index: 99;
  }
  .banner-header h1 {
    font-size: 60px;
    color: #fff;
    position: relative;
    font-family: 'Gilda Display', serif;
    font-weight: 700;
  }
  .banner-header h5 {
    color: #fff;
    font-size: 15px;
    /* font-family: 'Barlow Condensed', sans-serif; */
    text-transform: uppercase;
    letter-spacing: 2px !important;
    margin-bottom: 10px;
  }
  .text-justify {
    text-align: justify !important;
}
  .project-banner h5{
    letter-spacing: 1px;
  }
  .project-banner .banner-top{
    margin-top: 80px;
  }
  .banner-header::before{
    content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: black;
  opacity: 0.3;
  }

  /* Location */
  .tour-card {
    border-radius: 2px;
    min-height: 420px;
    overflow: hidden;
    padding: 20px;
    position: relative;
  }
  .tour-card.two-card{
    min-height: 320px;
  }
  .tour-card img {
    object-fit: cover;
    object-position: 10% 30%;
    transition: all .4s;
    z-index: 2;
  }
  .tour-card .content {
    align-items: end;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    text-shadow: 2px 2px 5px #333;
    z-index: 3;
  }
  .tour-card .content, .tour-card img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .tour-card:hover img {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
  .bg-light-gold{
    background-color: #f8f5f0;
  }
  .content .title{
    font-size: 25px;
    color: white;
  }
  .details button {
    rotate: -90deg;
    margin-top: 30px;
    position: absolute;
    right: 3px;
    top: 3%;
    border: 1px solid white;
    background-color: transparent;
    color: white;
    font-size: 18px;
    letter-spacing: 1px;
    z-index: 99;
  }
  .project-info h6{
    font-size: 19px;
    color: #B79231;
    font-family: 'Gilda Display', serif;
    font-weight: 600;
  }
  .project-info .box{
    padding: 20px;
  background-color: white;
  border-radius: 2px;
  line-height: 1;
  border: 1px solid #f1eeeb;
  border-radius: 2px;
  box-shadow: #d2bfa5 1.95px 1.95px 2.6px;
  }
  .project-info .box h6{
    width: 100%;
    border-bottom: 1px solid #B79231;
    padding-bottom: 10px;
  }
  .project-info .box:last-child{
    border: none;
  }
  .project-info span{
    font-size: 16px;
    font-family: 'Gilda Display', serif;
    font-weight: 400;
  }
  .mobile-seen{
    display: none;
  }
  .projects:hover .mobile-seen{
    display: block;
  }
  .mobile-seen ul{
    padding: 0;
    flex-direction: column;
  }
  .mobile-seen ul i{
    font-size: 10px;
  }
  
  @media (max-width:786px){
    .banner-header h1{
      font-size: 25px;
    }
    .banner-header{
      height: 45vh;
    }
    .banner-header h5{
      letter-spacing: 1px;
      font-size: 13px;
    }
    .project-banner{
      height: 75vh;
    }
    .sec-img{
      display: none;
    }
    .first-img{
      margin-top: 20px !important;
    }
    .first-img img{
      width: 100% ;
    }
    .col-md-6.pe-0{
      padding-right: 0.7rem !important;
    }
    .col-md-6.ps-0{
      padding-left: 0.7rem !important;
    }
    .merge-section{
      /* flex-direction:column-reverse; */
    }
  }