/* Wrapper Styles */
.news-page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* min-height: 100vh; */
    padding: 40px 20px;
}

/* Content Wrapper */
.content-wrapper {
    max-width: 1000px;
    width: 100%;
    text-align: center;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow: hidden;
}

/* Banner Image */
.press-banner {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 20px;
}

/* Title Styles */
.press-title {
    font-size: 2.3rem;
    font-weight: 700;
    margin-bottom: 10px;
}


/* Content Styles */
.press-content {
    text-align: left;
    font-size: 1.1rem;
    line-height: 1.8;
    color: #4a4a4a;
}

.press-content p {
    margin-bottom: 15px;
}

.press-content a {
    color: #aa8453;
    text-decoration: none;
    font-weight: 600;
}

.press-content a:hover {
    text-decoration: underline;
}
