.div-container {
  padding: 0 7%;
}

.bg-cream {
  background: #f8f5f0;
}

.banner-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* background: rgba(0, 0, 0, 0.6); */
}

.banner-slider .slick-slide img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.sub-menus .active {
  color: #aa8453;
  font-weight: 600;
}

.banner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 999;
}

.banner-content p {
  font-size: 16px;
  text-transform: uppercase;
  word-spacing: 4px;
  letter-spacing: 5px;
}

.banner-content h1 {
  font-size: 3.5rem;
  letter-spacing: 10px;
  font-family: "Gilda Display", serif;
}

.ps-projects .ps-dropdown {
  min-width: 212px;
  opacity: 0;
  overflow: auto;
  position: absolute;
  right: -30px;
  scrollbar-width: thin;
  top: 35px;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  transition: all 0.4s ease;
  visibility: hidden;
  z-index: 30;
}

.ps-items {
  background-color: #fff;
  max-height: 300px;
  overflow: auto;
  position: relative;
}

.ps-projects:hover .ps-dropdown {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  visibility: visible;
}

.ps-items a {
  padding: 8px 25px;
  color: #222 !important;
  display: block;
  margin: 0 !important;
  font-size: 17px !important;
  /* font-family: 'Barlow', sans-serif !important; */
  letter-spacing: 0px !important;
}

.ps-items a:hover {
  background-color: #aa8453;
  color: white !important;
}

.contact-modal {
  top: 20px !important;
}

.contact-modal .ant-modal-content {
  border-radius: 1px;
  background-color: #f8f5f0;
  padding: 20px 35px;
}

.contact-modal .ant-modal-header {
  background-color: #f8f5f0;
}

.contact-modal .ant-modal-title {
  font-size: 26px;
  font-family: "Gilda Display", serif;
  font-weight: 400;
  color: #aa8453;
  position: relative;
  margin-bottom: 3%;
  line-height: 1.25em;
  text-align: left;
}

.reservation {
  text-orientation: sideways;
  margin: 0;
  position: absolute;
  top: 45%;
  left: 10px;
  z-index: 999;
}

.reservation .icon {
  width: 50px;
  height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  border-radius: 100%;
  color: white;
  z-index: 999;
  position: absolute;
  top: 130px;
  left: 68px;
}

.reservation .call {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 5px;
  font-size: 16px;
  line-height: 1.75em;
  transform: rotate(270deg);
}


.reservation .call a {
  color: #b79231 !important;
}

.banner-slider .slick-prev,
.banner-slider .slick-next {
  z-index: 2;
}

.banner-slider .slick-dots {
  bottom: 10px;
  z-index: 2;
}

/* Navbar styles */
.navbar {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.8rem 3rem !important;
  color: white;
  z-index: 1000;
  display: flex;
  justify-content: space-evenly !important;
  align-items: center;
  background: transparent;
  transition: background-color 0.3s ease;
}

.navbar.scrolled {
  background: rgba(0, 0, 0, 0.8);
}

.bg-overlay {
  background-color: #222;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: 999;
}

.banner-button {
  color: #fff;
  padding: 10px 18px;
  font-size: 16px;
  border: 1px solid white;
  text-decoration: none;
  background-color: transparent;
  transition: 0.5s all;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: inline;
}

.banner-button:hover {
  background-color: #aa8453;
}

.navbar a {
  color: white;
  text-decoration: none;
  margin: 0 1rem;
  font-size: 17px;
  letter-spacing: 1px;
}

.navbar a:hover {
  color: #b79231;
}

.navbar img {
  height: 55px;
}

.navbar h3 {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 1px;
  /* font-family: 'Barlow', sans-serif; */
  font-family: "Gilda Display", serif;
}

.navbar ul {
  list-style: none;
  display: flex;
  margin-bottom: 0px;

}

/*
.banner video {
    height: 100vh;
    width: 100%;
    object-fit: cover;
} */

.viewprojects {
  padding-bottom: 4%;
}

.viewprojects h2 {
  font-size: 46px;
  font-family: "Gilda Display", serif;
  font-weight: 400;
  color: #222;
  position: relative;
  margin-bottom: 3%;
  line-height: 1.25em;
  padding-top: 4%;
}

.viewprojects .item {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  transition: transform 0.3s ease;
}

.viewprojects .item:hover {
  transform: scale(1.05);
}

.position-re {
  position: relative;
}

.o-hidden {
  overflow: hidden;
}

.viewprojects .item img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  height: 550px;
  object-fit: cover;
}

.viewprojects .item .title {
  position: absolute;
  bottom: 20px;
  width: 100%;
  color: white;
  padding: 20px;
  font-size: 28px;
  box-sizing: border-box;
  padding-bottom: 20px;
  overflow: hidden;
  letter-spacing: 3px;
}

.viewprojects .item .title::after {
  content: "";
  display: block;
  width: 50%;
  height: 2px;
  background: white;
  margin: 10px 4px;
  transition: width 0.5s ease;
}

.viewprojects .item:hover .title::after {
  width: 100%;
}

.viewprojects .item:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

/* .viewprojects .item .details{

} */

.viewprojects .item .details button {
  rotate: -90deg;
  margin-top: 30px;
  position: absolute;
  left: 80%;
  top: 3%;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  font-size: 16px;
  letter-spacing: 2px;

}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.viewprojects .item:hover .overlay {
  opacity: 1;
}

.overlay-content {
  font-size: 2rem;
  /* Adjust as needed */
  text-align: center;
}

.ourachievements {
  padding-bottom: 5%;
}

.ourachievements h2 {
  font-size: 46px;
  font-family: "Gilda Display", serif;
  font-weight: 400;
  color: #222;
  position: relative;
  margin-bottom: 3%;
  line-height: 1.25em;
  text-align: center;
  padding-top: 4%;
}

.font-big {
  font-size: 60px;
  text-align: center;
}

.font-big1 {
  font-size: 50px;
}

.featureprojects {
  padding-bottom: 5%;
}

.featureprojects h2 {
  font-size: 46px;
  font-family: "Gilda Display", serif;
  font-weight: 400;
  color: #f8f5f0;
  position: relative;
  margin-bottom: 3%;
  line-height: 1.25em;
  text-align: left;
}

.subTitle {
  color: #aa8453;
  font-size: 17px;
  letter-spacing: 1px;
  font-family: "Gilda Display", serif;
  font-weight: 500;
}

.section-padding {
  padding: 80px 0;
}

.bgblack {
  background-color: #222;
}

.proj-box {
  background-color: #f8f5f0;
  margin-bottom: 30px;
  position: relative;
}

.proj-box img {
  height: 450px;
  width: 100%;
  object-fit: cover;
}

.project-date {
  display: inline-block;
  position: absolute;
  top: 20px;
  left: 20px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  padding: 5px 10px;
  text-align: center;
  z-index: 99;
}

.project-date span,
.font-Gilda {
  font-family: "Gilda Display", serif !important;
}


.project-overlay {
  background-color: #222;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  z-index: 99;
  transition: 0.5s all;
}

.proj-text {
  padding: 15px;
  position: absolute;
  width: 90%;
  background-color: white;
  left: 17px;
  bottom: -19%;
  z-index: 999;
  transition: 0.5s all;
}

.proj-text span {
  font-family: "Gilda Display", serif;
}

.particular-project {
  cursor: pointer;
}

.particular-project:hover .proj-text {
  bottom: 2%;
}

.particular-project:hover .project-overlay {
  opacity: 0.5;
}

.proj-name {
  color: #b79231;
  font-size: 25px;
  font-family: "Gilda Display", serif;
}

.area-section {
  margin-top: 15px;
}

.area-section div {
  font-family: "Gilda Display", serif;
}

.area-section i {
  color: #b79231;
}

.button-div {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

.button-div button {
  background-color: #b79231;
  border: none;
  color: white;
  padding: 10px 15px;
}

.proj-title {
  text-align: center;
}

.top-builders {
  padding-bottom: 5%;
}

.top-builders h2 {
  font-size: 46px;
  font-family: "Gilda Display", serif;
  font-weight: 400;
  color: #222;
  position: relative;
  margin-bottom: 3%;
  line-height: 1.25em;
  text-align: center;
  padding-top: 4%;
}

.topbuilder-slider .slide {
  display: flex !important;
  justify-content: center;
}

.testimonials {
  background: url("../Images/image-1.jpg");
  /* background-color: #222; */
  background-attachment: fixed;
  background-position: bottom;
}

.testimonials h2 {
  font-size: 46px;
  font-family: "Gilda Display", serif;
  font-weight: 400;
  color: white;
  position: relative;
  margin-bottom: 3%;
  line-height: 1.25em;
}

.testimon-content {
  width: 65%;
  margin: auto;
  position: relative;
  z-index: 999;
}

.author-img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  float: left;
  overflow: hidden;
  object-fit: cover;
  margin-left: 25px;
}

.info h4 {
  font-size: 18px;
  margin-bottom: 0px;
  padding-top: 0px;
  font-weight: 500;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.info span {
  font-size: 14px;
  color: #fff;
  line-height: 1em;
}

.text-right {
  text-align: right;
}

.justify-space-between {
  justify-content: space-between;
}

.testi-slider {
  width: 60%;
  margin: 0% auto;
  transform: translateY(30px);
  background-color: #f8f5f0;
  padding: 3%;
}

.testi-slider .review {
  text-align: center;
}

.testi-slider .name {
  font-size: 25px;
  text-align: center;
  margin-top: 25px;
}

.testimon-content .slick-dots li.slick-active button::before {
  background-color: #b79231;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 3px;
  border: none;
}

.testimon-content .slick-dots li button::before {
  content: "";
}

.testimon-content .slick-dots li button {
  width: 20px;
  height: 20px;
  margin: 0 3px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #adadad;
}

.footer {
  background-color: #333333;
  display: flex;
  justify-content: space-evenly;
  color: #f2f2f2;
  padding: 5%;
}

.footer .address img {
  height: 100px;
  width: auto;
}

.footer .explore ul {
  list-style: none;
  padding-left: 0px;
}

.footer .explore span {
  font-size: 25px;
  padding-bottom: 10px;
  display: block;
}

.footer .explore ul li {
  padding: 2px 0px;
}

.footer .address span {
  font-size: 25px;
  padding-bottom: 10px;
  display: block;
}

.footer .address .contact {
  margin-top: 15px;
}

.footer .address .contact div {
  padding: 5px 0px;
}

.footer .address .social {
  display: flex;
  padding-top: 15px;
}

.footer .address .social a {
  margin-right: 10px;
  border: 1px solid beige;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.footer .address .social div i {
  font-size: 20px;
  line-height: 0;
}

.viewallprojects {
  display: flex;
  justify-content: end;
  margin-top: 90px;
}

.viewallprojects button {
  border: 1px solid #b79231;
  background: transparent;
  color: #f2f2f2;
  padding: 10px;
}

.side-info .section i {
  font-size: 17px;
}

.side-info .section div {
  font-size: 18px;
}

.side-info p {
  font-family: "Gilda Display", serif;
  font-weight: 500;
  line-height: 1.25em;
  font-size: 24px;
  color: black;
}

.contact-form {
  padding: 45px;
  border-radius: 3px;
  background: white;
}

.contact-form h2 {
  font-size: 28px;
  font-family: "Gilda Display", serif;
  font-weight: 600;
  position: relative;
  margin-bottom: 3%;
  line-height: 1.25em;
}

.form-luxury {
  display: block;
  width: 100%;
  font-size: 15px;
  color: #222;
  padding: 15px 40px 15px 15px;
  background: #f8f5f0;
  border: none;
  box-shadow: none;
  border-radius: 0;
  line-height: 22px;
  /* margin-bottom: 20px; */
}

.span-error {
  font-size: 12px;
  font-weight: 600;
}

.form-luxury:focus {
  outline: 1px solid #aa8453;
}

.form-luxury::placeholder {
  font-size: 15px;
  color: #666 !important;
}

.submit-btn {
  display: block;
  padding: 18px;
  border: none;
  width: 100%;
  background: #aa8453;
  color: #fff;
  font-size: 15px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-out;
  letter-spacing: 3px;
  text-transform: uppercase;
  border-radius: 3px;
}

.slide img {
  width: 105px;
  height: 65px;
  object-fit: contain;
}

.top-build {
  font-size: 28px;
  font-family: "Gilda Display", serif;
  font-weight: 600;
  position: relative;
  margin-bottom: 3%;
  line-height: 1.25em;
}

.side-info .icon {
  width: 35px;
  height: 35px;
  border: 1px solid black;
  animation: pulse 2s infinite;
  border-radius: 100%;
  color: black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
}

.achivement-side img {
  height: 380px;
  width: 261px;
  object-fit: cover;
}

.achivement-side .first-img {
  margin-top: 90px;
}

.static-box {
  margin-right: 10px;
  background: transparent;
  padding: 20px 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  line-height: 1;
  border: 1px solid #f1eeeb;
  border-radius: 5px;
  box-shadow: #d2bfa5 1.95px 1.95px 2.6px;
}

.static-box.new{
  height: 250px;
}

.static-box:hover{
  transform: scale(1.05);
}

.static-box i {
  font-size: 24px;
  color: #aa8453;
}

.counts {
  font-size: 20px;
  font-family: "Gilda Display", serif;
  font-weight: 400;
  color: black;
  position: relative;
  margin-bottom: 3%;
  line-height: 1.25em;
  text-align: center;
  overflow: hidden;
}

.mb-100 {
  margin-bottom: 100px;
}

.brochure-button {
  color: #aa8453;
  padding: 10px 21px;
  font-size: 16px;
  border: 1px solid #aa8453;
  text-decoration: none;
  background-color: transparent;
  transition: 0.5s all;
}

.brochure-button:hover {
  background-color: #aa8453;
  color: white;
}

.vid-butn .icon {
  color: #aa8453;
  width: 100px;
  height: 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  font-size: 40px;
  position: relative;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.vid-butn .icon::before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  background: #222222;
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  -o-transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  transition: all 0.5s cubic-bezier(1, 0, 0, 1);
}

.vid-butn .icon {
  color: white;
  line-height: 95px;
  text-align: center;
  font-size: 40px;
  display: inline-block;
  padding-left: 5px;
}

.vid-butn .icon::after {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  border: 1px solid #eee;
  border-radius: 50%;
  z-index: -1;
}

.vid-butn:hover .icon {
  color: #aa8453;
}

.vid-butn:hover .icon::before {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.vid-butn {
  position: absolute;
  top: 45%;
  left: 41%;
  z-index: 99;
  cursor: pointer;
}

.bd-number-pagination i,
.bd-number-pagination {
  background: transparent;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 37px;
  text-align: center;
  color: #666;
  font-weight: 400;
  border: 1px solid rgba(102, 102, 102, 0.1);
  /* font-family: "Barlow", sans-serif; */
  font-size: 16px;
  border-radius: 100%;
}

.bd-number-pagination.activepage {
  background-color: transparent;
  border: 1px solid #aa8453;
  color: #aa8453;
}

.bd-number-pagination:hover {
  border: 1px solid #aa8453;
  color: #aa8453;
}

.about-side-img {
  height: 405px;
  object-fit: cover;
  padding: 5px;
  border: 2px solid #aa8453;
  border-radius: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.backgroun-no-repeat {
  background-repeat: no-repeat !important;
}

.background-size-cover {
  background-size: cover !important;
}

@media (min-width: 786px) {
  .mobile-menu {
    display: none;
  }

  .sidebar-icon {
    display: none;
  }

}

@media (max-width: 786px) {
  .banner-content {
    top: 44%;
  }

  .testimon-content {
    width: 100%;
  }

  .quote-width {
    width: 50px;
  }

  .author-img {
    width: 50px;
    height: 50px;
  }

  .banner-content h1 {
    font-size: 25px;
    font-weight: 600;
  }

  .reservation {
    display: none;
  }

  .banner-container {
    height: 75vh;
  }

  .viewprojects h2,
  .featureprojects h2 {
    font-size: 32px;
  }

  .viewprojects {
    padding: 40px 0;
  }

  .viewprojects .item .details button {
    font-size: 14px;
  }

  .contact-form {
    padding: 25px;
    margin-top: 20px;
  }

  .sub-menus {
    display: none !important;
  }

  .achivement-side img {
    width: 100%;
  }

  .mobile-overflow-x {
    overflow-x: hidden;
  }

  .mobile-menu {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #222222;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 0px;
    display: block;
  }

  .navbar img {
    height: 45px;
  }

  .navbar h3 {
    font-size: 17px;
  }

  .navbar a {
    /* width: 50%; */
    margin: 0;
  }

  .navbar {
    padding: 28px 15px !important;
    justify-content: space-between !important;
  }

  .sidebar-icon i {
    font-size: 25px;
  }

  .mobile-menu a {
    padding: 8px 8px 4px 15px;
    text-decoration: none;
    font-size: 16px;
    color: white;
    display: block;
    transition: 0.3s;
  }

  .mobile-menu i {
    font-weight: 500;
  }

  .main-div {
    padding: 20px;
    width: 100%;
  }

  .cancel-button {
    position: absolute;
    top: 0;
    right: 20px;
    color: white;
  }

  .mobile-menu a:hover {
    padding-left: 30px !important;
  }

  .cta-section h2 {
    font-size: 25px !important;
  }

  .cta-section p {
    font-size: 16px !important;
  }

  .mobile-flex {
    flex-direction: column;
  }

  .map-img {
    width: 100%;
  }

  .line-top,
  .line-tops {
    display: none !important;
  }
}

@media (max-width: 510px) {
  .banner-content {
    top: 44%;
    width: 85%;
  }

  .container {
    padding: 0 20px !important;
  }
}

.footer .locations-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; /* Adds spacing between columns and rows */
}

.footer .locations-section li {
  display: inline-block;
  margin: 0 15px 0 0;
  position: relative;
  padding: 0 0 0 12px;
}

.footer .locations-section li a {
  color: white;
  text-decoration: none;
}

.footer .locations-section li::before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  left: 0;
  top: 50%;
  background-color: white;
  border-radius: 100%;
  transform: translateY(-50%);
}

.footer .project-location {
  border-left: 6px solid #aa8453;
  padding-left: 20px;

}

.rera-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: #aa8453;
  margin-bottom: 20px;
  border-radius: 2px;
}


.rera-box p {
  font-size: 18px;
}

.rera-slider {
  font-size: 20px;
  color: white;
  font-weight: 500;
}

.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 60px;
  height: 60px;
  z-index: 1000;
  padding: 8px;
  border-radius: 50%;
  background-color: #2fba43;
  display: flex;
  align-items: center;

}


.whatsapp-icon::before {
  animation: pulseWhatsapp 1s infinite;
  background: #2fba43;
  border-radius: 50%;
  content: "";
  display: block;
  height: 100%;
  opacity: 0;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  z-index: -1;
}

.whatsapp-icon img {
  width: 100%;
  height: auto;
}

div.disclaimer_text * {
  color: #fff !important;
  line-height: 22px;
}

div.disclaimer_text div {
  /* text-align: left !important; */
}

div.disclaimer_text a strong,
div.disclaimer_text a {
  color: #b79231 !important;
}

@keyframes pulseWhatsapp {
  from {
    transform: scale(1);
    opacity: 0.6;
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

.geo-location i {
  font-size: 32px;
  color: #aa8453;
}

.geo-location.first {
  position: absolute;
  top: 29%;
  left: 34%;
}

.geo-location.second {
  position: absolute;
  top: 54%;
  left: 22%;
}

/* styles.css */
.curved-dotted-line {
  width: 300px;
  /* Width of the line */
  height: 10px;
  /* Height can be adjusted */
  border-top: 2px dotted black;
  /* Dotted border */
  border-radius: 10px;
  /* Curves the edges */
  position: absolute;
  top: 2%;
  overflow: hidden;
}

.curved-dotted-line::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  /* Background color */
  border-radius: 10px;
  /* Make the background curve */
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(5deg);
  /* Rotate for a slight curve */
  box-shadow: 0 0 0 50px black;
  /* Shadow to create the dotted effect */
}

.first-location {
  position: absolute;
  top: 16%;
  left: 19%;
}

.second-location {
  position: absolute;
  top: 39%;
  left: 13%;
}

.line-top {
  width: 378px;
  height: 220px;
  background: transparent;
  border: none;
  border-top: dashed 3px#aa8453;
  border-radius: 50%;
  display: flex;
  transform: rotate(4deg);
}

.line-tops {
  width: 442px;
  height: 196px;
  background: transparent;
  border: none;
  border-top: dashed 3px#aa8453;
  border-radius: 50%;
  display: flex;
  transform: rotate(2deg);
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #222222;
  line-height: 0;
}

.social-icons i {
  font-size: 22px;
  color: black;
}

.other-branches {
  padding:25px;
  background-color: #c19f7370;
  border-radius: 9px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.blog-tags {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.blog-tags li {
  margin: 3px !important;
  padding: 8px 20px;
  background-color: #aa8453;
  border: 1px solid #fff;
  color: #666 !important;

}

.blog-tags li a {
  font-size: 15px;
  color: white !important;
  text-transform: none;
}

.border-roundeds {
  border-radius: 5px;
}


/* chatbot hide free line */
div.tawk-footer {
  display: none !important;
  visibility: hidden !important;
}

.move_arrow {
  animation: move_right .9s alternate ease-in infinite both ;
  display: grid;
  place-items: center;
  width: 32px;
  height: 32px;
}

@keyframes move_right {
  from {
    transform: translateX(-5px) scale(.8);
    background-color: #fff;
    color: #222;
  }
  to {
    transform: translateX(15px) scale(1);
    background-color: #222;
    color: #fff;
  }
}

.Terms-Data table{
  background: transparent !important;
  border-collapse: initial;
  border-spacing: 0 10px;
}

.Terms-Data table tr td{
  background-color: #EAD5B1;
  padding-left: 20px;
}

.Terms-Data table tr td:first-child{
  width: 5%;
  border-radius: 5px 0px 0px 5px;
}

.Terms-Data table tr td:last-child{
  border-radius: 0px 5px 5px 0px;
}

@media (max-width:768px) {

  .Terms-Data table{
    background-color: transparent !important;
    /* min-width: 100%; */
    width: 1000px;
    overflow-x: scroll;
    scrollbar-width:thin;
  }

  .Terms-Data table tr td{
    background-color: #EAD5B1;
    padding-left: 10px;
  }
}

.Terms-Table tr.type-heading td {
  margin-top: 20px; /* Adds top padding to heading rows */
  background-color: transparent; /* Keeps the header background clean */
  border: none;
  font-weight: bold;
  text-align: left;
  padding-top: 20px;
}

.Terms-Table tr.type-heading td h6{
  font-size: 18px;
  font-weight: 550;
}

.Pricing-Section{
  border: 1px solid #aa8453;
  padding: 10px;
}