@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Gilda+Display&display=swap');

* {
  /* font-family: 'Barlow', sans-serif; */
  font-family: "Gilda Display", serif;
}

body{
overflow-x:hidden ;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.email-input {
  padding: 12px 20px;
  width: 350px;
  outline: none;
  border: 1px solid #aa8453;
}

.email-input:focus {
  background-color: #f4faf0;
}

.subscribe-button {
  padding: 12px 20px;
  border: 1px solid #aa8453;
  background-color: #aa8453;
  color: #fff;
}

@media (width<=768px) {
  .email-input {
      width: 100%;
  }
  .subscribe-button {
      margin-top: 20px;
  }
}

input[type=file]::file-selector-button {
  border: none;
  padding: .23em .9em;
  border-radius: 5px;
  background-color: #121113db !important;
  color: #fff;
  transition: .4s;
  margin-left: -5px;
  cursor: pointer;
  font-size: 13px;
}

input[type=file]::file-selector-button:hover {
  background-color: #12111374;
}

.tawk-max-container .tawk-footer > .tawk-padding-small .tawk-branding{
  display: none !important;
}